<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="지정년도"
            type="year"
            name="year"
            default="today"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" :isFirstValue="false" label="부서" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="해제기간"
            :range="true"
            name="releasePeriod"
            v-model="searchParam.releasePeriod"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="유소견자 이력 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          :columnSetting="false"
          :checkClickFlag="false"
          @tableDataChange="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable && grid.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid.data.filter(item => item.editFlag === 'U')"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveSuspect"
                @btnCallback="saveCallback" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'suspect-user',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'userId', },
          { index: 1, colName: 'userId', },
          { index: 2, colName: 'userId', },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'healthType',
            field: 'healthType',
            label: '건강구분',
            style: 'width:70px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'appointDate',
            field: 'appointDate',
            label: '지정일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'releaseDate',
            field: 'releaseDate',
            label: '해제일',
            type: 'date',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'releaseRemark',
            field: 'releaseRemark',
            label: '해제비고',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
      },
      period: [],
      searchParam: {
        plantCd: '',
        deptCd: '',
        year: '',
        userId: '',
        releasePeriod: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      userName: '',
      isRegister: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.suspect.user.history.url;
      this.saveUrl = transactionConfig.hea.checkup.suspect.user.save.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = this.$_.map(_result.data, item => {
          return this.$_.extend(item, {
            releaseDateSave: item.releaseDate,
            releaseClear: false,
          })
        });
        this.isRegister = false;
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'releaseDate') {
        if (props.row.releaseDateSave 
          && !props.row.releaseDate) {
          /**
           * 해제일이 저장되어져 있지만, 지우는 경우
           */
          this.$set(props.row, 'releaseClear', true)
        } else if (props.row.releaseDate && props.row.releaseClear) {
          this.$set(props.row, 'releaseClear', false)
        }
      }
    },
    saveSuspect() {
      let vaildPass = true;
      let releaseFilter = this.$_.filter(this.grid.data, item => {
        return !item.releaseDate
      })
      let blankUser = [];
      if (releaseFilter && releaseFilter.length > 0) {
        let users = this.$_.map(this.$_.pickBy(this.$_.countBy(releaseFilter, 'userId'), item => {
          return item > 1
        }), (value, key) => {
          return {
            userId: key,
          }
        })
        if (!this.$_.isEmpty(users)) {
          vaildPass = false;
          blankUser = this.$_.map(users, item => {
            return this.$_.find(this.grid.data, { userId: item.userId }).userName
          })
        }
      }

      if (vaildPass) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: `동일 사람에게 유소견자 해제일이\n두 개 이상 비어있을 수 없습니다.\n[${blankUser.join(',')}] `, 
          type: 'warning', // success / info / warning / error
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
